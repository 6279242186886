<template>
  <div id="login">
    <el-form>
      <img class="logo" src="../../assets/images/mine/logo.png" alt="">
      <h1 class="title">商户管理系统</h1>
      <!-- <li class="login-way">
        <span :class="isActive && 'way-active'" @click="isActive = true"
          >密码登录</span
        >
        <i class="mid-line"></i>
        <span :class="!isActive && 'way-active'" @click="isActive = false"
          >验证码登录</span
        >
      </li> -->
      <!-- 账户 -->
      <el-input v-model.number="params.userMobile" placeholder="请填写手机号" maxlength="11" prefix-icon="el-icon-phone"
        clearable @input="onSpIdList"></el-input>
      <!-- 密码 -->
      <el-input v-if="isActive" v-model="params.password" placeholder="请填写密码" show-password prefix-icon="el-icon-lock"
        @keydown.enter="login()"></el-input>
      <!-- 验证码 -->
      <el-input v-if="!isActive" v-model="securitycode" placeholder="请填写验证码" prefix-icon="el-icon-message">
        <template #append>
          <span class="securitycode" @click="setSecuritycode()">发送验证码</span>
        </template>
      </el-input>
      <!-- 服务商 -->
      <el-select v-model="params.spId" placeholder="请选择服务商" style="width: 100%">
        <template #prefix>
          <i class="el-input__icon el-icon-office-building"></i>
        </template>
        <el-option v-for="item in spIdList" :key="item.spId" :label="item.spName" :value="item.spId">
        </el-option>
      </el-select>
      <el-button class="login-btn" type="primary" @click="login()">登 录</el-button>
    </el-form>
    <div class="reg" @click="linkReg">忘记密码？</div>
  </div>
</template>

<script>
import { getToken, getSpIdList } from "@/api/setting/user";
export default {
  data() {
    return {
      spIdList: [],
      params: {
        userMobile: "", //账户
        password: "", //密码
        spId: null,//服务商id
      },
      isActive: true, //登录方式
    };
  },
  methods: {
    // 【请求】获取服务商列表
    onSpIdList: _.debounce(function () {
      let data = {
        sysId: 1,//1平台端。2：门店端
        userMobile: this.params.userMobile,
      };
      getSpIdList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.spIdList = res.data;
          this.$nextTick(() => {
            if (res.data) {
              this.params.spId = res.data[0].spId
            }
          });
        }
      })
    }, 1000),

    // 登录
    login: _.throttle(function () {
      let data = this.params;
      getToken(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.$router.push({
            path: "/home",
          });
          window.localStorage.setItem('playOne-merchant-token', res.data);//存入token
        }
      });
    }, 1000),

    // 短信验证
    setSecuritycode: _.throttle(function () {
      this.$message({
        message: "发送成功",
        type: "success",
      });
    }, 1000),
    // 跳转注册
    linkReg: _.throttle(function () {
      this.$router.push({
        path: "/mine/register",
      });
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#login {
  width: 3rem;
  height: 4rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
}

.el-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: .68rem;
  height: .68rem;
}

.title {
  font-size: .28rem;
  font-family: 微软雅黑;
  letter-spacing: 3px;
  font-weight: bold;
  margin-bottom: .3rem;
}

.login-btn {
  width: 100%;
  margin-top: .3rem;
}

.login-way {
  width: 100%;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-around;
  font-size: 0.2rem;
  font-weight: bold;
}

.login-way>span {
  cursor: pointer;
  color: #999;
}

.way-active {
  color: #000 !important;
}

.mid-line {
  width: 1px;
  background: rgb(219, 219, 219);
}

.securitycode {
  color: #409eff;
  cursor: pointer;
}

.reg {
  margin: 0.1rem 0;
  width: 100%;
  text-align: center;
  color: #409eff;
  cursor: pointer;
  font-size: 0.12rem;
}
</style>